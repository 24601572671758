var deviceAgent = navigator.userAgent.toLowerCase();

var isTouchDevice = (deviceAgent.match(/(iphone|ipod|ipad)/) ||
deviceAgent.match(/(android)/)  ||
deviceAgent.match(/(iemobile)/) ||
deviceAgent.match(/iphone/i) ||
deviceAgent.match(/ipad/i) ||
deviceAgent.match(/ipod/i) ||
deviceAgent.match(/blackberry/i) ||
deviceAgent.match(/bada/i));

var startTime = new Date().getTime();


function allowNiceScrollOnBody() {
  var ua = window.navigator.userAgent;

  var msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return false;
  }

  var trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    var rv = ua.indexOf('rv:');
    return false;
  }

  var edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return false;
  }

  // other browser
  return true;
}


$( window ).on( "load", function(){

  // check if loading at least 1 second
  var loadTime = new Date().getTime();
  var loadingTime = loadTime - startTime;

  if(loadingTime < 1000){
    setTimeout(function() {
      startApp();
    }, (1000 - loadingTime) );
  } else {
    startApp();
  }
});

// start base app
function startApp() {

  if(isTouchDevice){
    $("body").addClass("touch-devices");
  } else {
    $("body").addClass("no-touch-devices");
  }

  // hide loader
  $(".pageLoader").addClass('hide-loader');
  $("body").removeClass("overflow-hidden");

  setTimeout(function() {
    $(".pageLoader").remove();
  }, 700);

  // enable niceScroll on body
  if(!isTouchDevice && allowNiceScrollOnBody()){
    $("body").niceScroll({
     cursorcolor: "#334350",
     cursorwidth: "11px",
     background: "#1d2933",
     cursorborder: 'none',
     cursorborderradius: 5,
     zindex: 111
    });
  }
  enableNiceScroll($(".do-nicescrol"));


  // run script on page
  $("body").trigger("runPage");

}


// Page header
//
var mainMenuItem = $('.HeaderButtonHorizontal-labelIcon'),
    mainMenuAnim = ('animated infinite wobble');
$('.HeaderButton').hover(
    function () {
      console.log($(this).find(mainMenuItem));
        $(this).find(mainMenuItem).addClass(mainMenuAnim);
    },
    function () {
        $(this).find(mainMenuItem).removeClass(mainMenuAnim);
    }
);

$(window).scroll(function () {
  var scroll = $(window).scrollTop();
  if (scroll >= 10) {
    $(".PageHeader").addClass("showBkg");
  } else {
    $(".PageHeader").removeClass("showBkg");
  }
});

$('body').on('click', '.PageHeader-toggle', function (e) {
  $('.PageHeader').toggleClass('open-navbar');
  $(this).toggleClass('open');

  e.preventDefault();
});


// Page Footer
//
$('.FooterTabs-carousel .TabsComponent-HeaderButton .ButtonOneBorder').click(function(){
	var tab_id = $(this).attr('data-tab');

	$('.FooterTabs-carousel .TabsComponent-HeaderButton .ButtonOneBorder').removeClass('ButtonOneBorder--active');
	$('.FooterTabs-carousel .TabsComponent-Content').removeClass('TabsComponent-Content--active');

	$(this).addClass('ButtonOneBorder--active');
	$("#"+tab_id).removeClass('TabsComponent-Content--left TabsComponent-Content--right');
	$("#"+tab_id).addClass('TabsComponent-Content--active');

  setTimeout(function(){
    var index = $('.FooterTabs-carousel .TabsComponent-Content--active').index();
    $('.FooterTabs-carousel .TabsComponent-Content').removeClass('TabsComponent-Content--right TabsComponent-Content--left');
    $('.FooterTabs-carousel .TabsComponent-Content:gt(' + index + ')').addClass('TabsComponent-Content--right');
    $('.FooterTabs-carousel .TabsComponent-Content:lt(' + index + ')').addClass('TabsComponent-Content--left');
  }, 300);
});

$('.Select').click(function(e){
  e.preventDefault();
  $(this).toggleClass('is-open');
});

$('.btn-expand-footer, .btn-expand-footer-action').click(function (e) {
    if ($('.PageFooter-Expand').hasClass('PageFooter-Expand--enter')) {
        $('.btn-expand-footer').removeClass('btn-expand-footer-action--leave');
        $('.PageFooter-Expand').removeClass('PageFooter-Expand--enter');
        $('body').getNiceScroll().show();
    } else {
        $('.btn-expand-footer').addClass('btn-expand-footer-action--leave');
        $('.PageFooter-Expand').addClass('PageFooter-Expand--enter');
        $('body').getNiceScroll().hide();
    }
   e.preventDefault();
});




// NiceScroll functions
function enableNiceScroll(selector) {
  if(!isTouchDevice){
    selector.niceScroll({
     cursorcolor: "#334350",
     cursorwidth: "11px",
     background: "#1d2933",
     cursorborder: 'none',
     cursorborderradius: 5
    });
  } else {
    selector.css({overflow: 'auto'});
  }
}

function disabledNiceScroll(selector) {
  if(!isTouchDevice){
    selector.getNiceScroll().hide();
  }
}

function resizeNiceScroll(selector) {
  if(!isTouchDevice){
    selector.getNiceScroll().resize();
  }
}

// Tabs
//
$('body').on('click', '[data-toggle="tab"]', function (e) {
  e.preventDefault();

  var $container = $(this).closest('.tab-container'),
      tab_id = $(this).attr('href');

  $('> .nav li', $container).removeClass('active');
  $('.tab-pane', $container).removeClass('active');

  $(this).parent().addClass('active');

  $(tab_id).addClass('active');
});


// Modal
//
$('body').on('click', '[data-toggle="modal"]', function (e) {
  e.preventDefault();

  var idModal = $(this).data('target');
  $('body').addClass('overflow-hidden');
  $('.modal-background').toggleClass('active');
  $(idModal).toggleClass('active bounceInUp');
  $('.modal .modal-title').toggleClass('bounceInUp');
  resizeNiceScroll($(idModal).find('.do-nicescrol'));

  setTimeout(function() {
    disabledNiceScroll($('body'));
  }, 100);
});

$('body').on('click', '[data-toggle="close-modal"]', function (e) {
  e.preventDefault();
  $('body').removeClass('overflow-hidden');
  $('.modal-background').removeClass('active');
  $('.modal').removeClass('active bounceInUp');
  $('.modal .modal-title').removeClass('bounceInUp');

  if($(".video-js").length > 0){
    setTimeout(function() {
      $(".video-js")[0].player.pause();
    }),10;
  }
});
